import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChatDBService } from '../chat-db.service';
import { User } from '../interfaces/User';

@Component({
  selector: 'app-change-pw',
  templateUrl: './change-pw.component.html',
  styleUrls: ['./change-pw.component.scss']
})
export class ChangePWComponent implements OnInit {

  changePwForm: FormGroup
  errorMsg: string

  constructor(public dialogRef: MatDialogRef<ChangePWComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User,
    private formBuilder: FormBuilder,
    private chatDBService: ChatDBService) {

    chatDBService.registerOnMessageSubject(event => {

      switch (event.type) {
        case "ChangePasswordFailed":
          this.errorMsg = "Wrong Password, pls retry"
          break;

        case "ChangedUserPassword":
          this.dialogRef.close({ passwordChanged: true })
          break;

        default:
          break;
      }

    })

  }

  ngOnInit() {
    this.changePwForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
    });
  }

  get f() { return this.changePwForm.controls; }

  onNoClick(): void {
    this.dialogRef.close({ passwordChanged: false });
  }

  resetErrorMsg() {
    this.errorMsg = ""
  }

  onSubmit() {
    const oldPassword: string = this.f.oldPassword.value
    const newPassword: string = this.f.newPassword.value
    this.chatDBService.changeUserPassword(this.data.email, oldPassword, newPassword)
  }

}
