import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChatMainComponent } from './chat-main/chat-main.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './auth-guard.service';
import { RegisterComponent } from './register/register.component';
import {ChatListComponent} from './chat-list/chat-list.component';

//default
//{ path: '', redirectTo: '/dashboard', pathMatch: 'full' },


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'chat', component: ChatMainComponent, canActivate: [AuthGuardService]},
  { path: 'chat-list', component: ChatListComponent },
  { path: 'login', component: LoginComponent  },
  { path: 'register', component: RegisterComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

