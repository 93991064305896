import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// Material section
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule} from '@angular/material/button';
import { MatIconModule} from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatTabsModule} from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import {MatDialogModule} from '@angular/material/dialog'; 
import {MatCardModule, MatCheckboxModule, MatInputModule} from '@angular/material';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTooltipModule} from '@angular/material/tooltip'; 
// Routing
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { ChatDBService } from './chat-db.service';
import { ChatMainComponent } from './chat-main/chat-main.component';
import { LoginComponent } from './login/login.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule} from '@angular/material/select';

import { ChatRoomSelectComponent } from './chat-room-select/chat-room-select.component';
import { RegisterComponent } from './register/register.component';
import { ChatListComponent } from './chat-list/chat-list.component';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { SiedebarComponent } from './siedebar/siedebar.component';
import { PeopleInRoomComponent } from './people-in-room/people-in-room.component';
import { InviteComponent } from './invite/invite.component';
import { RoomSettingsComponent } from './room-settings/room-settings.component';
import { AdminComponent } from './admin/admin.component';
import { UserTypeComponent } from './user-type/user-type.component';
import { UsersettingsComponent } from './usersettings/usersettings.component';
import { ChangePWComponent } from './change-pw/change-pw.component';
import { ChangeUserNameComponent } from './change-user-name/change-user-name.component';
import {ScrollDispatchModule} from '@angular/cdk/scrolling';



@NgModule({
  declarations: [
    AppComponent,
    ChatMainComponent,
    LoginComponent,
    ChatRoomSelectComponent,
    RegisterComponent,
    ChatListComponent,
    ChatBoxComponent,
    SiedebarComponent,
    PeopleInRoomComponent,
    InviteComponent,
    RoomSettingsComponent,
    AdminComponent,
    UserTypeComponent,
    UsersettingsComponent,
    ChangePWComponent,
    ChangeUserNameComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,

    // Material
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTabsModule,
    MatListModule,
    MatExpansionModule,
    MatGridListModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatDialogModule,
    MatAutocompleteModule,
    ScrollDispatchModule
  ],
  entryComponents: [InviteComponent,ChangePWComponent,ChangeUserNameComponent],
  providers: [ChatDBService],
  bootstrap: [AppComponent]
})
export class AppModule { }
