import { Injectable } from '@angular/core';
import { ChatDBService } from './chat-db.service';
import { BehaviorSubject } from 'rxjs';
import { User } from './interfaces/User';
import { UserCredentials } from './interfaces/UserCredentials';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject = new BehaviorSubject<User>(null);
  private timer: NodeJS.Timer
  public currentUser = this.currentUserSubject.asObservable();

  constructor(private chatDBService: ChatDBService) {
    chatDBService.registerOnMessageSubject((event) => {

      switch (event.type) {
        case "LoggedIn":
          const user = event.value
          this.currentUserSubject.next(user);
          break;

        case "LoggedOut":
          this.currentUserSubject.next(null);
          break;

        case "UserRenamed":
          const renamedUser = event.value
          this.currentUserSubject.next(renamedUser);
          break;

        default:
          break;
      }
    })
  }

  registerOnUserSubject(callback: (user: User) => void) {
    this.currentUserSubject.subscribe(callback)
  }

  get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(userCredentials: UserCredentials) {
    this.timer = this.chatDBService.stableLogin(userCredentials)
  }

  clearTimer() {
    clearInterval(this.timer);
  }

  logout() {
    this.chatDBService.logout(this.timer)
    this.currentUserSubject.next(null)
  }

}
