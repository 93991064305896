import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-siedebar',
  templateUrl: './siedebar.component.html',
  styleUrls: ['./siedebar.component.scss']
})
export class SiedebarComponent implements OnInit {
 
  title = 'chatroom';

  admins = [
    { name: 'user1', state: 'online', status: 'carpe diem'},
    { name: 'user2', state: 'offline', status: 'hello world'},
    { name: 'user3', state: 'online', status: 'langeweile~'}];

  isSidenavOpen = true;
  contentMargin = 297;
  expand = true;
  state = false;

  onSidenavToggle() {
    this.isSidenavOpen = !this.isSidenavOpen;
    this.state = !this.state;

    if (!this.isSidenavOpen) {
      this.contentMargin = 100;
    } else {
      this.contentMargin = 297;
    }

    this.expand = !this.expand;
  }


  constructor() { }

  ngOnInit() {
  }

}
