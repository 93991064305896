import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ChatDBService } from '../chat-db.service';
import { Room } from '../interfaces/Room';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth.service';
import { UsersWithRoomAndRights } from '../interfaces/UsersWithRoomAndRights';





@Component({
  selector: 'app-chat-room-select',
  templateUrl: './chat-room-select.component.html',
  styleUrls: ['./chat-room-select.component.scss']
})
export class ChatRoomSelectComponent implements OnInit {

  @Output() room = new EventEmitter();
  @Input() usersWithRoomAndRights: BehaviorSubject<UsersWithRoomAndRights[]> = new BehaviorSubject(null);
  @Output() roomsEventEmitter = new EventEmitter();

  currentRoom : string = null;

  rooms: Room[] = [{ roomName: "Room1", users: [], voice: true, inviteRequired: false }, { roomName: "Room2", users: [], voice: true, inviteRequired: false }, { roomName: "Room3", users: [], voice: true, inviteRequired: false }, { roomName: "Room4", users: [], voice: true, inviteRequired: false }]


  constructor(private chatDBService: ChatDBService, private authService: AuthService) {

  }

  ngOnInit() {
    this.roomsEventEmitter.next(this.rooms);

    this.usersWithRoomAndRights.subscribe((users) => {

      if (users == null) {
        return;
      }
      this.rooms.forEach((room) => {
        room.users = [];
        users.forEach((user) => {
          if (user.roomName == room.roomName) {
            room.users.push(user);
          }
        })
      })

    })



    this.chatDBService.registerOnMessageSubject((event => {
      switch (event.type) {
        case "RoomJoined":

          break;

        case "RoomLeft":
          break;

        case "InvitedOfRoomRequired":
          this.changeInviteOfRoomRequired(event.value.roomName, event.value.inviteRequired);
          break;

        case "VoiceInRoomRequired":
          this.changeVoiceInRoomRequired(event.value.roomName, event.value.voice);
          break;
      }
    }))


  }



  changeInviteOfRoomRequired(roomName: string, inviteRequired: boolean) {
    this.getRoomByRoomName(roomName).inviteRequired = inviteRequired;
  }

  changeVoiceInRoomRequired(roomName: string, voice: boolean) {
    this.getRoomByRoomName(roomName).voice = voice;
  }


  newRoomValue(room: string) {
      this.room.next(room)
      this.currentRoom = room;
      this.chatDBService.joinRoom(room);
  
  }

  leaveRoomButtonClicked(room: string) {
    this.chatDBService.leaveRoom(room);

  }

  getCurrentUserByRoom(room: string) {

    if (this.usersWithRoomAndRights.value == null) {
      return null;
    }

    return this.usersWithRoomAndRights.value.find((user) => {
      return user.roomName == room && user.email == this.authService.currentUserValue.email;
    })
  }


  getRoomByRoomName(roomName: string) {
    return this.rooms.find((room) => {
      return room.roomName == roomName;
    })
  }


}
