import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChatDBService } from '../chat-db.service';
import { Validation } from '../Classes/Validation';
import { UserCredentials } from '../interfaces/UserCredentials';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private chatDBService: ChatDBService) {

    this.chatDBService.registerOnMessageSubject((event) => {
      switch (event.type) {
        case "UserRegistered":
          this.router.navigateByUrl("login")
          break;

        default:
          break;
      }
    })
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]+[a-zA-Z]{2,4}$')]],
      name: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_]*')]],
      password1: ['', [Validators.required,]],
      password2: ['', [Validators.required, Validation.compareValidator('password1')]],
    });
  }

  get f() { return this.registerForm.controls; }

  onSubmit() {

    const userCredentials: UserCredentials = { password: this.f.password1.value, email: this.f.email.value, name: this.f.name.value }
    this.chatDBService.registerUser(userCredentials.email, userCredentials.name, userCredentials.password)

  }

  getErrorMessageEmail() {
    return this.registerForm.get('email').hasError('required') ? 'You must enter a value' :
      this.registerForm.get('email').hasError('pattern') ? 'Not a valid email' :
        '';
  }

  getErrorMessageName() {
    return this.registerForm.get('name').hasError('required') ? 'You must enter a value' :
      this.registerForm.get('name').hasError('pattern') ? 'asdf_dd' :
        '';
  }

  getErrorMessagePW() {
    return this.registerForm.get('password2').hasError('required') ? 'You must enter a value' :
      this.registerForm.get('password2').hasError('compare') ? 'Password does not match' :
        '';
  }
}
