import { Component, OnInit, Input } from '@angular/core';
import { User } from '../interfaces/User';
import { ChatDBService } from '../chat-db.service';
import { Room } from '../interfaces/Room';
import { UserWithRights } from '../interfaces/UserWithRights';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  @Input() user: UserWithRights
  @Input() room: string
  @Input() currentUser: UserWithRights

  constructor(private chatdb: ChatDBService) { }

  ngOnInit() {
  }

  grantOp(){
    this.chatdb.grantOp(this.room, this.user.email, true)
  }

  revokeOp(){
    this.chatdb.grantOp(this.room, this.user.email, false)
  }

  kickFromRoom(){
    this.chatdb.inviteToRoom(this.room, this.user.email, false)
    this.chatdb.kickUser(this.room, this.user.email)
  }

  grantVoice(){
    this.chatdb.GrantVoice(this.room, this.user.email, true)
  }

  revokeVoice(){
    this.chatdb.GrantVoice(this.room, this.user.email, false)
  }

}
