import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Validation } from '../Classes/Validation';
import { Room } from '../interfaces/Room';
import { ChatDBService } from '../chat-db.service';

export interface User {
  name: string;
  email: string
}

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  myControl: FormControl
  userMailsInRoom: string[] = []
  options: string[] = []
  filteredOptions: Observable<string[]>;

  constructor(public dialogRef: MatDialogRef<InviteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Room,
    private dbService: ChatDBService) {
    
    this.dbService.registerToOnlineUsersSubject(usersMailsOnline => {
      this.getUserMails()
      this.options = usersMailsOnline.filter(val => !this.userMailsInRoom.includes(val))
    })
  }


  ngOnInit() {
    this.myControl = new FormControl('', [Validation.isValidUser(this.options)])
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  getUserMails(){
    this.data.users.forEach(val => {
      this.userMailsInRoom.push(val.email)
    })
  }

  getErrorMessage() {
    return this.myControl.hasError('selectedUser') ? 'Select user' :
      '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    const userToInvite = this.myControl.value
    this.dialogRef.close(userToInvite)
  }
}
