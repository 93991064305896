import { Component, OnInit, Input } from '@angular/core';
import { ChatDBService } from '../chat-db.service';
import { Room } from '../interfaces/Room';
import { MatDialog } from '@angular/material/dialog';
import { InviteComponent } from '../invite/invite.component';
import { UserWithRights } from '../interfaces/UserWithRights';

@Component({
  selector: 'app-room-settings',
  templateUrl: './room-settings.component.html',
  styleUrls: ['./room-settings.component.scss']
})
export class RoomSettingsComponent implements OnInit {

  @Input() currentUser: UserWithRights
  @Input() room: Room

  currentRoomAndUsers 
  constructor(private chatdb: ChatDBService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.currentRoomAndUsers = this.room
    console.log(this.room)
  }

  setRommPrivate(){
    this.chatdb.setInviteRoom(this.room.roomName, true)
    this.inviteToRoom(this.currentUser.email)
  }

  setRoomPublic(){
    this.chatdb.setInviteRoom(this.room.roomName, false)
  }

  revokeVoiceFromRoom(){
    this.chatdb.setVoiceRoom(this.room.roomName, false)
  }

  setVoiceToRoom(){
    this.chatdb.setVoiceRoom(this.room.roomName, true)
  }

  inviteToRoom(mail: string){
    this.chatdb.inviteToRoom(this.room.roomName, mail, true)
  }

  leaveRoom(){
    this.chatdb.leaveRoom(this.room.roomName)
  }

  openInviteDialog(): void {
    const dialogRef = this.dialog.open(InviteComponent, {
      width: '250px',
      data: this.currentRoomAndUsers
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.inviteToRoom(result)
      }
    });
  }

}
