import { ValidationErrors, AbstractControl } from '@angular/forms';
import { Subscription} from 'rxjs';


export class Validation {

  static isValidUser(options:string[]){
    return (control: AbstractControl): ValidationErrors | null => {
     
      const selectedUser = options.filter(email => email == control.value)
      
      if(selectedUser.length > 0){
        return null
      }
      else
        return {selectedUser: true}
    }
  }
  
  static compareValidator(controlNameToCompare:string){
    return (control: AbstractControl): ValidationErrors | null => {
      if(control.value === null || control.value.lenght === 0){
        return null
      }
      
      const controlToCompare = control.root.get(controlNameToCompare)
      if(controlToCompare){
        const sub:Subscription = controlToCompare.valueChanges.subscribe(()=>{
          control.updateValueAndValidity();
          sub.unsubscribe();
        })
      }
      return controlToCompare && controlToCompare.value !== control.value ? {compare:true} : null
    }
  }
}