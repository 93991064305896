import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ChatDBService } from '../chat-db.service';
import { AuthService } from '../auth.service';
import { UserWithRights } from '../interfaces/UserWithRights';
import { UsersWithRoomAndRights } from '../interfaces/UsersWithRoomAndRights';






@Component({
  selector: 'app-people-in-room',
  templateUrl: './people-in-room.component.html',
  styleUrls: ['./people-in-room.component.scss']
})
export class PeopleInRoomComponent implements OnInit {

  @Output() usersWithRoomAndRights = new EventEmitter();

  currentRoom: string;
  users: UsersWithRoomAndRights[] = [];



  constructor(private chatDBService: ChatDBService, private authService: AuthService) {

  }

  ngOnInit() {
    this.chatDBService.registerOnMessageSubject((event => {
      let user: UsersWithRoomAndRights;

      switch (event.type) {
        case "RoomJoined":
          this.users.push({ name: event.value.name, email: event.value.email, roomName: event.value.roomName, op: false, voice: true })
          this.update();
          break;

        case "RoomLeft":
          if (this.authService.currentUserValue.email == event.value.email) {
            this.users = this.users.filter((element) => {
              return !(element.roomName == event.value.roomName)
            })
            this.update();
            break
          }
          this.users = this.users.filter((element) => {
            return !(element.email == event.value.email && element.roomName == event.value.roomName)
          })
          this.update();
          break;

        case "OpGranted":
          user = null;
          user = this.users.find((user) => {
            return user.email == event.value.email && user.roomName == event.value.roomName;
          })

          if (user != null) {
            user.op = event.value.op;
          }
          this.update();
          break;

        case "VoiceGranted":
          user = null;
          user = this.users.find((user) => {
            return user.email == event.value.email && user.roomName == event.value.roomName;
          })
          if (user != null) {
            user.voice = event.value.voice;
          }
          this.update();
          break;



        case "UserRenamed":
          this.users.forEach((user) => {
            if (user.email == event.value.email) {
              user.name = event.value.name
            }
          })
          this.update();
          break;

        case "KickEvent":
          this.leaveRoomBecauseOfKick(event.value.roomName, event.value.email);
          break;

          case "InvitedToRoom":
          //this.joinRoomBecauseOfInvite(event.value.roomName, event.value.email);
            break;
      }
    }))

  }

  leaveRoomBecauseOfKick(roomName: string, email: string) {
    if (this.authService.currentUserValue.email == email)
      this.chatDBService.leaveRoom(roomName)
  }

  joinRoomBecauseOfInvite( roomName: string, userEmail: string) {
    if (userEmail == this.authService.currentUserValue.email) {
      this.chatDBService.joinRoom(roomName)
    }
  }

  getCurrentUserForRoom(room: string) {

    return this.users.find((user) => {
      return user.email == this.authService.currentUserValue.email && user.roomName == room;
    })
  }

  update() {
    this.usersWithRoomAndRights.next(this.users)

  }

}
