import { Component, OnInit, Input } from '@angular/core';
import { ChatDBService } from '../chat-db.service';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth.service';
import { UsersWithRoomAndRights } from '../interfaces/UsersWithRoomAndRights';
import { Room } from '../interfaces/Room';




@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss']
})
export class ChatBoxComponent implements OnInit {
  messages: Message[] = []
  roomMessages: Message[] = []
  finalMessages: Message[][] = []
  messageText = "";

  //Aktuell ausgewähler Raum
  @Input() room: BehaviorSubject<string> = new BehaviorSubject("NoRoom")
  //Alle bekannten User mit ihrem Rechten und Räumen
  @Input() usersWithRoomAndRights: BehaviorSubject<UsersWithRoomAndRights[]> = new BehaviorSubject(null);
  //Alle Räume mit ihren Einstellungen
  @Input() rooms: Room[] = [];

  constructor(private chatDBService: ChatDBService, private authService: AuthService) {

  }

  ngOnInit() {

    this.room.subscribe((room) => { this.update(); })

    this.chatDBService.registerOnMessageSubject((event) => {
      switch (event.type) {
        case "MessageSendToRoom":
          this.messages.push({ email: event.value.email, message: event.value.message, roomName: event.value.roomName, userName: event.value.userName, time: new Date() });
          this.update()
          break;

        case "UserRenamed":
          this.messages.forEach((message) => {
            if (message.email == event.value.email) {
              message.userName = event.value.name
            }
          })
          this.update();
          break;
      }

    })

  }

  // Sends a message to a room
  sendMessage(text: string) {


    let currentUserRights = this.getCurrentUserByRoom(this.room.value);
    let room = this.getRoomByRoomName(this.room.value)

    if (room && ((room.voice && currentUserRights != null && currentUserRights.voice == true) || !room.voice)) {
      this.chatDBService.sendMessageToRoom(this.room.value, text);
    }

    this.messageText = "";
  }

  updateRoomMessages() {
    this.roomMessages = this.messages.filter((message => {
      return message.roomName == this.room.value;
    }))
  }


  updateFinalMessages() {
    let index = 0;
    let lastMessage: Message = null;
    this.finalMessages = [];

    this.roomMessages.forEach((roomMessage: Message) => {

      if (this.finalMessages[index] == null) {
        this.finalMessages[index] = [];
      }

      if (lastMessage != null && (roomMessage.email != lastMessage.email || (roomMessage.time.getTime() - this.finalMessages[index][0].time.getTime()) >= (1000 * 60 * 60 * 24) || (roomMessage.time.getDate() - this.finalMessages[index][0].time.getDate()) >= 1)) {
        ++index;
        this.finalMessages[index] = [];
      }

      this.finalMessages[index].push(roomMessage);
      lastMessage = roomMessage;
    })
  }


  update() {
    this.updateRoomMessages();
    this.updateFinalMessages();

  }

  getCurrentUserByRoom(room: string) {

    if (this.usersWithRoomAndRights.value == null) {
      return null;
    }
    return this.usersWithRoomAndRights.value.find((user) => {
      return user.roomName == room && user.email == this.authService.currentUserValue.email;
    })
  }


  getRoomByRoomName(roomName: string) {
    return this.rooms.find((room) => {
      return room.roomName == roomName;
    })
  }



}
