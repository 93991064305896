import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserWithRights } from '../interfaces/UserWithRights';
import { UsersWithRoomAndRights } from '../interfaces/UsersWithRoomAndRights';
import { Room } from '../interfaces/Room';




@Component({
  selector: 'app-chat-main',
  templateUrl: './chat-main.component.html',
  styleUrls: ['./chat-main.component.scss']
})
export class ChatMainComponent implements OnInit {
currentRoom : BehaviorSubject<string> = new BehaviorSubject("NoRoom");
usersWithRoomAndRights : BehaviorSubject<UsersWithRoomAndRights[]> = new BehaviorSubject(null);
rooms : Room[]

  ngOnInit() {
  }

}
