import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../interfaces/User';
import { ChatDBService } from '../chat-db.service';
import { FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-change-user-name',
  templateUrl: './change-user-name.component.html',
  styleUrls: ['./change-user-name.component.scss']
})
export class ChangeUserNameComponent implements OnInit {

  nameForm: FormControl

  constructor(public dialogRef: MatDialogRef<ChangeUserNameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User,
    private chatDBService: ChatDBService) {

    this.chatDBService.registerOnMessageSubject(event => {

      switch (event.type) {
        case "UserRenamed":
          this.dialogRef.close({ email: event.value.email, name: event.value.name })
          break;

        default:
          break;
      }

    })

  }

  ngOnInit() {
    this.nameForm = new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9_]*')])
  }

  getErrorMessageName() {
    return this.nameForm.hasError('required') ? 'You must enter a value' :
      this.nameForm.hasError('pattern') ? 'pattern asdf_dd' :
        '';
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    const newName: string = this.nameForm.value
    this.chatDBService.renameUser(this.data.email, newName)
  }
}
