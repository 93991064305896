import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../auth.service';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../interfaces/User';
import { ChangePWComponent } from '../change-pw/change-pw.component';
import { ChangeUserNameComponent } from '../change-user-name/change-user-name.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-usersettings',
  templateUrl: './usersettings.component.html',
  styleUrls: ['./usersettings.component.scss']
})
export class UsersettingsComponent implements OnInit, OnDestroy {
  

  currentUser: User
  unsub: Subscription

  constructor(private authService: AuthService,
    public dialog: MatDialog,
    private router: Router) { }

  ngOnInit() {
    this.unsub = this.authService.currentUser.subscribe(user =>{
      this.currentUser = user
    })
  }

  ngOnDestroy(): void {
    this.unsub.unsubscribe()
  }

  logout(){
    this.authService.logout()
    this.router.navigateByUrl("/login")
  }

  openDialogChangePW(): void {
    const dialogRef = this.dialog.open(ChangePWComponent, {
      width: '250px',
      data: this.currentUser
    });

    dialogRef.afterClosed().subscribe(result => {
  
      if(result.passwordChanged){
        this.authService.logout()
        this.router.navigateByUrl("/login")
      }
    });
  }

  openDialogChangeUserName(): void {
    const dialogRef = this.dialog.open(ChangeUserNameComponent, {
      width: '250px',
      data: this.currentUser
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
       
      }
    });
  }
}
