import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { DBData } from './interfaces/DBData';
import { User } from './interfaces/User';
import { UserCredentials } from './interfaces/UserCredentials';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class ChatDBService {

  onMessageObservable = new Observable;
  connection: WebSocket;
  subject: Subject<any>;
  //private onlineUsers: string[] = []
  private onlineUsersSubject: BehaviorSubject<string[]> = new BehaviorSubject([]);

  constructor() {

    this.subject = new Subject<any>()
    this.connection = new WebSocket('ws://localhost:8080/chatSocket/')


    // Error listener
    this.connection.onerror = (error) => {
      console.log('WebSocket Error ' + error);
    };


    // Event listener
    this.connection.onmessage = (e) => {
      var o = JSON.parse(e.data);
      console.log("Server", o);

      this.subject.next(o);
    };


    this.registerOnMessageSubject((event) => {

      switch (event.type) {
        case "LoggedIn":
          this.pushSubjectValue(event.value.email)
          this.InformLogin()
          break;

        case "LoginInformEvent":
          if (!this.onlineUsersSubject.value.find((user) => {
            return user == event.value.email;
          })) {
            this.pushSubjectValue(event.value.email)
            this.InformLogin();
          }
          break;
        case "LogoutInformEvent":
          this.onlineUsersSubject.next(this.onlineUsersSubject.value.filter((user) => {
            return user != event.value.email;
          }))
          break;
        default:
          break;
      }
    })



  }


  // Registriert einen Callback (Subject) welcher ausgeführt wird wenn die Datenbank ein Event schmeißt
  registerOnMessageSubject(callback: (event: DBEvent<any>) => void) {
    this.subject.subscribe(callback)
  }


  //Sendet die Events an das Backend
  sendEvent(type: string, data: any): void {
    const command = {
      type: type,
      value: data
    }

    if (this.connection.readyState === 1) {
      this.connection.send(JSON.stringify(command));
    }
    else {
      this.connection.onopen = () => {
        this.connection.send(JSON.stringify(command));
      }
    }
  }

  //Logt einen user aus, es ist möglich einen Timer zu übergeben falls der Stable Login genutzt wurde
  logout(timer: NodeJS.Timer = null) {
    if (timer != null) {
      clearInterval(timer);
    }
    this.InformLogout()
    this.sendEvent("Logout", {})
  }


  //Logt einen User im Intervall immerwieder erneut an, Default sind 10 Sek
  stableLogin(user: UserCredentials, timeInMs: number = 10000): NodeJS.Timer {
    this.tryLogin(user)

    return setInterval(() => {
      this.tryLogin(user)
    }, timeInMs)

  }

  // Logged sich als User ein.
  tryLogin(user: UserCredentials) {
    this.sendEvent("Login", user)
  }

  //Betritt einen Raum
  joinRoom(room: string) {
    this.sendEvent("JoinRoom", { "roomName": room })
  }

  //Verlässt einen Raum
  leaveRoom(room: string) {
    this.sendEvent("LeaveRoom", { "roomName": room })

  }


  //Definiert für einen Raum, ob eine Einladung erforderlich ist
  setInviteRoom(roomName: string, inviteRequired: boolean) {
    this.sendEvent("SetInviteRoom", { roomName: roomName, inviteRequired: inviteRequired })
  }


  // Definiert, ob ein Raum moderiert ist, d.h. ob nur Personen mit Voice im Raum sprechen können.
  setVoiceRoom(roomName: string, voice: boolean) {
    this.sendEvent("SetVoiceRoom", { roomName: roomName, voice: voice })
  }

  // Gibt einer Person im Raum Operator Rechte
  grantOp(roomName: string, email: string, op: boolean) {
    this.sendEvent("GrantOp", { roomName: roomName, email: email, op: op })
  }
  //Gibt einer Person im Raum Voice
  GrantVoice(roomName: string, email: string, voice: boolean) {
    this.sendEvent("GrantVoice", { roomName: roomName, email: email, voice: voice })
  }

  //Sendet eine Message zu einem Raum
  sendMessageToRoom(roomName: string, text: string) {
    this.sendEvent("SendMessageToRoom", { "roomName": roomName, "message": text })
  }

  // Spricht eine Einladung für einen Raum aus.
  inviteToRoom(roomName: string, email: string, invite: boolean) {
    this.sendEvent("InviteToRoom", { roomName: roomName, email: email, invite: invite })
  }

  // Setzt das Password neu.
  changeUserPassword(email: string, oldPassword: string, newPassword: string) {
    this.sendEvent("ChangeUserPassword", { email: email, oldPassword: oldPassword, newPassword: newPassword })
  }

  // Registriert einen neuen User.
  registerUser(email: string, name: string, password: string) {
    this.sendEvent("RegisterUser", { email: email, name: name, password: password })
  }

  // Bennent den aktuellen Nutzer um.
  renameUser(email: string, userName: string) {
    this.sendEvent("RenameUser", { email: email, userName: userName })
  }

  kickUser(roomName: string, email: string) {

    this.sendEvent("KickCommand", { roomName: roomName, email: email })
  }

  InformLogin() {
    this.sendEvent("LoginInformCommand", {})
  }

  InformLogout() {
    this.sendEvent("LogoutInformCommand", {})
  }

  pushSubjectValue(value: string) {
    let onlineUsersSubjectValue = this.onlineUsersSubject.value;
    onlineUsersSubjectValue.push(value);
    this.onlineUsersSubject.next(onlineUsersSubjectValue);
  }

  getOnlineUsers() : string[] {
    return this.onlineUsersSubject.value
  }

  registerToOnlineUsersSubject(callback: (users: string[]) => void) {
    this.onlineUsersSubject.subscribe(callback);
  }

}
