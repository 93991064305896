import { Component, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { ChatMainComponent } from '../chat-main/chat-main.component';
import { AuthGuardService } from '../auth-guard.service';
import { LoginComponent } from '../login/login.component';
import { RegisterComponent } from '../register/register.component';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit {

  users = [
    { name: 'user1', state: 'online', status: 'carpe diem' },
    { name: 'user2', state: 'offline', status: 'hello world' },
    { name: 'user3', state: 'online', status: 'langeweile~' }];

  constructor() { }

  ngOnInit() {
  }

}