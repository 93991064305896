import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { User } from '../interfaces/User';
import { ChatDBService } from '../chat-db.service';
import { Subscription } from 'rxjs';
import { UserCredentials } from '../interfaces/UserCredentials';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  unsub: Subscription
  errorMsg: string

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private chatDBService: ChatDBService) {

      this.chatDBService.registerOnMessageSubject(event => {
        if(event.type == "LogginFailed"){
          this.errorMsg = "Login Failed, pls retry"
          this.authService.clearTimer()
        }
      })
  }



  ngOnInit() {

    this.unsub = this.authService.currentUser.subscribe(user => {
      if (user) {
        this.router.navigateByUrl('chat')
      }
    })

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]+[a-zA-Z]{2,4}$')]],
      password: ['', [Validators.required]]
    });
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {

    const userCredentials: UserCredentials = { password: this.f.password.value, email: this.f.email.value }
    this.authService.login(userCredentials)

  }

  ngOnDestroy() {
    this.unsub.unsubscribe()
  }

  resetErrorMsg(){
    this.errorMsg = ""
  }

  getErrorMessageEmail() {
    return this.loginForm.get('email').hasError('required') ? 'You must enter a value' :
        this.loginForm.get('email').hasError('pattern') ? 'Not a valid email' :
            '';
  }

}
