import { Component, OnInit, Input } from '@angular/core';
import { UserWithRights } from '../interfaces/UserWithRights';

@Component({
  selector: 'app-user-type',
  templateUrl: './user-type.component.html',
  styleUrls: ['./user-type.component.scss']
})
export class UserTypeComponent implements OnInit {

  @Input() user: UserWithRights

  constructor() { }

  ngOnInit() {
  }

  getColor(user: UserWithRights){

    if(!user.voice && user.op){
      return "yellow"
    }
    
    if(user.op && user.voice){
      return "green"
    }

    if(user.voice && !user.op){
      return "blue"
    }
    
    return "black"
  }

  getUserType(user: UserWithRights){

    if(!user.voice && user.op){
      return "OP, No Voice"
    }

    if(user.op && user.voice){
      return "OP and Voice"
    }

    if(user.voice && !user.op){
      return "Voice"
    }

    return "No Rights"
  }
}
